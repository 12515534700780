#read-more {display:none;}
   
#read-more h2 {
 font-size: 26px;
 margin: 50px auto 10px;
}

.kk-home h2:first-child {
     margin: 10px auto 10px!important;}

   #read-more h1 {
 font-size: 30px;
 margin: 50px auto -20px;
}

#read-more p {
 padding: 10px 0px;
}

.kk-footer-container { 
	display: block;
width: 100%;
}

.kk-footer {
    display: flex;
	justify-content: space-between; margin: 0 auto;
	max-width: 1180px;
	padding: 50px 10px;
	width: 95%; 
}

.kk-footer a {
	color:#443832!important;

}

.kk-footer ul {
list-style: outside none none;
margin: 0;
    padding: 0 20px 0px 0px;
}

.kk-footer a:hover {
color:#443832!important; 
text-decoration:underline;
}


div#popular-searches {
    width: 100%;
  z-index:9999;
}

.kk-footer a, .kk-footer li {
    padding: 5px 0px;
}

li.kk-title {
    font-weight: 800;
}

li.kk-title.kk-nested {
    padding-top: 20px;
}

#popular-searches, #hide-links-trigger {display:none;}

@media (max-width: 999px) { 
.kk-footer ul {
list-style: outside none none;
    margin: 20px 0;
    padding: 0 5px;
    display: inline-block;
    width: 49%;
    vertical-align: top;
    float: left;
}
  
  .kk-footer {
    display: unset;
	justify-content: unset;
  }
  
}



@media (max-width: 799px) { 
	.kk-footer {
		padding: 20px 0px 0;
		text-align: left; 
		display:block;
	}
  .kk-footer ul{
        width: 100%;
  }
}

